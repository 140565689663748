<template>
  <!-- 위험성평가 시나리오 종합 목록 -->
  <c-table
    ref="table"
    title="LBL0001121"
    :columns="gridColumns"
    :data="summary.data"
    :merge="gridMerge"
    :gridHeight="grid.height"
    rowKey="processCd"
    selection="multiple"
  >
  <!-- 버튼 영역 -->
  <template slot="table-button">
    <q-btn-group outline >
      <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
    </q-btn-group>
  </template>
    <template v-slot:customArea="{ props, col }">
      <template v-if="col.name==='customCol'">
        <component
          :is="imprComponent"
          :col="col"
          :props="props"
          :inputEditable="false"
          :requestContentsCols="imprProps.requestContentsCols"
          :tableKey="imprProps.tableKey"
          :ibmTaskTypeCd="imprProps.ibmTaskTypeCd"
          :ibmTaskUnderTypeCd="imprProps.ibmTaskUnderTypeCd"
        />
      </template>
      <template v-else-if="col.name==='ramRiskLevelName'">
        <q-chip :style="`color:${props.row.riskColor};`" outline square>
          {{props.row[col.name]}}
        </q-chip>
      </template>
    </template>
  </c-table>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import reCheckMixin from './reCheckMixin'
export default {
  name: 'reCheckSummary',
  mixins: [reCheckMixin],
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramTechniqueCd: '',
        }
      },
    },
    summary: {
      type: Object,
      default: function() {
        return {
          data: [],
        }
      },
    },
    ramRiskAssessmentPlanIds: {
      type: Array,
      default: () => [],
    },
    charmTypeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      grid: {
        height: '550px',
        data: [],
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      editable: true,
      selectRisks: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    ramRiskAssessmentPlanIds: {
      handler: function () {
        this.getList()
      },
      deep: true,
    },
  },
  computed: {
    listUrl() {
      let url = '';
      if (this.popupParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP 아직..
        url = selectConfig.ram.hazop.summary.url
      } else if (this.popupParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        url = selectConfig.ram.fm.summary.url
      } else if (this.popupParam.ramTechniqueCd === 'RT00000030') {
        // CHARM 
        url = selectConfig.ram.charm.summary.result.url
      } 
      return url;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      // 선택된 위험성평가 정보가 있는 경우
      if (this.ramRiskAssessmentPlanIds && this.ramRiskAssessmentPlanIds.length > 0) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          ramRiskAssessmentPlanId: this.popupParam.ramRiskAssessmentPlanId,
          // ramTechniqueCd: this.popupParam.ramTechniqueCd,
          ramRiskAssessmentPlanIds: this.ramRiskAssessmentPlanIds,
        };
        this.$http.request((_result) => {
          this.$set(this.summary, 'data', _result.data)
        },);
      } else {
        this.$set(this.summary, 'data', [])
      }
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData, this.summary.data);
      }
    },
  }
};
</script>
